/** @format */

import { faBullseye, faCog, faTools } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Chip,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import { useFlag } from '@unleash/proxy-client-react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { api, url } from '../../api.ts';
import logo from '../../assets/images/logos/logo.png';
import manywattLogo from '../../assets/images/logos/manywatt_light.png';
import { logout, selectAuth } from '../../redux/authSlice';
import { ThemeProvider } from '../../theme';
import BetaBadge from '../misc/BetaBadge';

const styles = (theme) => ({
  title: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  subTitle: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledLogo = styled('img')(({ theme }) => ({
  height: 60,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const StyledMenuButtonIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (propName) => propName !== 'light',
})(({ theme, light }) => ({
  marginRight: theme.spacing(1),
  color: light ? '#408b6a' : undefined,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& > .MuiBadge-badge': {
    right: -12.5,
    top: '45%',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const drawerWidth = 240;

const navItems = ({ user, logoutAction }) => [
  {
    label: 'PORTAIL',
    path: '/gate',
    permission: user.has_access_to_gate,
    highlight: true,
  },
  {
    label: 'Leads',
    path: '/leads',
    permission: !user.is_marketing_only,
  },
  {
    label: 'Clients',
    path: '/clients',
    permission: true,
  },
  {
    label: 'Se déconnecter',
    action: logoutAction,
    permission: true,
  },
];

function MainMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [messageQueueCount, setMessageQueueCount] = useState(0);

  const dispatch = useDispatch();
  const { user } = useSelector(selectAuth);
  const navigate = useNavigate();
  // const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const isNewLogoEnabled = useFlag('global_new_logo');

  const container = useMemo(
    () => (window !== undefined ? () => window.document.body : undefined),
    [],
  );

  const loadMessageQueueCount = () => {
    api
      .get('/automaticmessagequeueentry/count/')
      .then((res) => {
        setMessageQueueCount(res.data.count);
      })
      .catch((err) => {
        setMessageQueueCount(0);
        console.error(err);
      });
  };

  /* useMemo(() => {
    const result = location.pathname.match(/^\/lead\/(\d+).* /i);
    if (result && result.length > 1) {
      const leadId = result[1];
      window.YTFeedbackForm.getClientJSApi(
        'c99e083f-bacc-446b-b903-e48f56ada531',
      ).then(async (form) => {
        console.log('form set');
        const blocks = form.getBlocks();
        const leadIdBlock = blocks.find(
          (b) => b.type === 'integer' && b.title.includes('lead'),
        );
        if (location.pathname.match(/^\/lead\/(\d+).* /i)) {
          form.setBlockValue(leadIdBlock.title, leadId);
        } else {
          form.setBlockValue(leadIdBlock.title, '');
        }
      });
    } else {
      window.YTFeedbackForm.getClientJSApi(
        'c99e083f-bacc-446b-b903-e48f56ada531',
      ).then(async (form) => {
        console.log('form delete');
        const blocks = form.getBlocks();
        const leadIdBlock = blocks.find(
          (b) => b.type === 'integer' && b.title.includes('lead'),
        );
        form.setBlockValue(leadIdBlock.id, '');
      });
    }
  }, [location.pathname]); */

  const handleMenu = (event) => {
    setAnchorEl(event.target);
  };

  const handleMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setAnchorEl2(null);
    setTimeout(() => {
      setAnchorEl2(null);
    }, 500);
  };

  const handleLogout = () => {
    handleClose();
    api
      .post('/logout/')
      .then(() => {
        dispatch(logout());
        if (
          window.google &&
          window.google.accounts &&
          window.google.accounts.id
        ) {
          window.google.accounts.id.disableAutoSelect();
        }
        navigate('/login');
        enqueueSnackbar('Déconnexion réussie.', { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Erreur : déconnexion impossible.', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    loadMessageQueueCount();
    setInterval(loadMessageQueueCount, 15000);
  }, []);

  return (
    <ThemeProvider>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              CRM
            </Typography>
            <Divider />
            <List>
              {navItems({
                user,
                logoutAction: handleLogout,
              })
                .filter((item) => item.permission)
                .map((item) => (
                  <ListItem key={item.label} disablePadding>
                    <ListItemButton
                      sx={{
                        textAlign: 'center',
                        backgroundColor: item.highlight
                          ? 'error.main'
                          : undefined,
                        color: item.highlight
                          ? 'error.contrastText'
                          : undefined,
                      }}
                      component={item.path ? Link : undefined}
                      to={item.path ? item.path : undefined}
                      onClick={item.action ? item.action : undefined}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Drawer>
      </Box>
      <AppBar position="fixed" color="default">
        <StyledToolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={8} md={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Link to="/">
                    <StyledLogo
                      src={isNewLogoEnabled ? manywattLogo : logo}
                      alt=""
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <Grid container justifyContent="center" alignItems="center">
                {!user.is_marketing_only && (
                  <Grid item>
                    <Button
                      color={
                        window.location.href.includes('lead')
                          ? 'primary'
                          : 'default'
                      }
                      variant="contained"
                      to="/leads"
                      component={Link}
                      sx={{
                        mr: 2,
                        fontColor: '#408b6a',
                      }}
                    >
                      <StyledMenuButtonIcon
                        icon={faBullseye}
                        light={!window.location.href.includes('lead')}
                      />
                      <span
                        style={{
                          color: window.location.href.includes('lead')
                            ? ''
                            : '#408b6a',
                        }}
                      >
                        Leads
                      </span>
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    color={
                      window.location.href.includes('clients')
                        ? 'primary'
                        : 'default'
                    }
                    variant="contained"
                    to="/clients"
                    component={Link}
                    sx={{
                      fontColor: '#408b6a',
                    }}
                  >
                    <StyledMenuButtonIcon
                      icon={faTools}
                      light={!window.location.href.includes('clients')}
                    />
                    <span
                      style={{
                        color: window.location.href.includes('clients')
                          ? ''
                          : '#408b6a',
                      }}
                    >
                      Clients
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
              >
                {!user.is_external_accountant && (
                  <Grid item>
                    <Badge
                      color="error"
                      badgeContent={messageQueueCount}
                      variant="dot"
                    >
                      <Button
                        variant="contained"
                        color="default"
                        onClick={handleMenu2}
                      >
                        <StyledMenuButtonIcon icon={faCog} />
                        Outils
                      </Button>
                    </Badge>
                    <Menu
                      id="menu-appbar-2"
                      anchorEl={anchorEl2}
                      keepMounted
                      open={Boolean(anchorEl2)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        component={Link}
                        to="/requests"
                        onClick={handleClose}
                      >
                        Nouvelle demande
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        component={Link}
                        to="/messagequeuemanager"
                        onClick={handleClose}
                      >
                        <StyledBadge
                          color="error"
                          badgeContent={messageQueueCount}
                        >
                          <BetaBadge>
                            <span>E-mails/SMS</span>
                          </BetaBadge>
                        </StyledBadge>
                      </MenuItem>
                      <Divider />
                      {user.is_superuser && (
                        <>
                          <MenuItem
                            component={Link}
                            to="/customer-area-credentials"
                            onClick={handleClose}
                          >
                            Accès admin espace client
                          </MenuItem>
                          <Divider />
                        </>
                      )}
                      {(user.is_superuser ||
                        user.is_project_manager ||
                        user.is_admin_leader ||
                        user.is_external_accountant ||
                        user.is_calendar_manager) && (
                        <>
                          <MenuItem
                            component={Link}
                            to="/interventions"
                            onClick={handleClose}
                            key="a"
                          >
                            Interventions
                          </MenuItem>
                          <Divider key="b" />
                        </>
                      )}
                      {(user.is_reviewer ||
                        user.is_superuser ||
                        user.is_project_manager) &&
                        !user.is_marketing_only && (
                          <>
                            <MenuItem
                              component={Link}
                              to="/calendar"
                              onClick={handleClose}
                              key="a"
                            >
                              Calendrier
                            </MenuItem>
                            <Divider key="b" />
                          </>
                        )}
                      {(user.is_superuser || user.is_project_manager) && (
                        <>
                          <MenuItem
                            component={Link}
                            to="/construction-review"
                            onClick={handleClose}
                            key="a"
                          >
                            <BetaBadge>
                              <span>Fin de chantier</span>
                            </BetaBadge>
                          </MenuItem>
                          <Divider key="b" />
                        </>
                      )}
                      {(user.is_reviewer || user.is_superuser) &&
                        !user.is_marketing_only && (
                          <>
                            <MenuItem
                              component={Link}
                              to="/review"
                              onClick={handleClose}
                              key="a"
                            >
                              Review
                            </MenuItem>
                            <Divider key="b" />
                          </>
                        )}
                      {(user.is_superuser ||
                        user.is_project_manager ||
                        user.is_stats_editor) &&
                        !user.is_marketing_only && (
                          <>
                            <MenuItem
                              component={Link}
                              to="/projectmanagers"
                              onClick={handleClose}
                              key="a"
                            >
                              Contrôle
                            </MenuItem>
                            {user.is_superuser && <Divider key="b" />}
                          </>
                        )}
                      {(user.is_superuser ||
                        user.is_marketing ||
                        user.is_marketing_only ||
                        user.is_commercial_manager) && (
                        <MenuItem
                          component={Link}
                          to="/baseoffers"
                          onClick={handleClose}
                        >
                          Offres internet
                        </MenuItem>
                      )}
                      {(user.is_technician_manager || user.is_superuser) && (
                        <>
                          <Divider key="a" />
                          <MenuItem
                            component={Link}
                            to="/technicians"
                            onClick={handleClose}
                            key="b"
                          >
                            Techniciens
                          </MenuItem>
                          <Divider key="c" />
                          <MenuItem
                            component={Link}
                            to="/bigchange"
                            onClick={handleClose}
                            key="d"
                          >
                            Configuration BigChange
                          </MenuItem>
                        </>
                      )}
                      {((user.is_staff && user.is_admin_leader) ||
                        user.is_superuser) && (
                        <>
                          <Divider key="a" />
                          <MenuItem
                            component={Link}
                            to="/order-sync"
                            onClick={handleClose}
                            key="b"
                          >
                            Synchro. des commandes
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </Grid>
                )}
                <Grid item>
                  <Button color="inherit" onClick={handleMenu}>
                    <Box mr={1}>
                      <Avatar
                        alt={`${
                          user.first_name
                        } ${user.last_name[0].toUpperCase()}.`}
                        src={`${url}/avatar/${user.id}`}
                      />
                    </Box>
                    {user.first_name} {user.last_name[0].toUpperCase()}.
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        width: 200,
                        maxWidth: '100%',
                      },
                    }}
                    MenuListProps={{
                      sx: {
                        paddingTop: 0,
                      },
                    }}
                  >
                    <MenuItem
                      disabled
                      sx={{
                        opacity: '1 !important',
                        bgcolor: 'primary.main',
                        py: 2,
                      }}
                    >
                      <ListItemText>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: 'primary.contrastText',
                          }}
                        >
                          Manywatt
                        </Typography>
                      </ListItemText>
                      <Typography variant="body2" color="text.secondary">
                        <Chip
                          label={`v${process.env.REACT_APP_VERSION}`}
                          size="small"
                          sx={{
                            bgcolor: 'white',
                          }}
                        />
                      </Typography>
                    </MenuItem>
                    <Divider sx={{ mt: '0 !important' }} />
                    <MenuItem
                      component={Link}
                      to="/app/account"
                      onClick={handleClose}
                    >
                      Mon compte
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                sx={{ display: { sm: 'none' } }}
              >
                <Grid item>
                  <IconButton
                    color="inherit"
                    edge="start"
                    onClick={handleDrawerToggle}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledToolbar>
      </AppBar>
      <Box sx={{ minHeight: 48 }} />
      {/* <Toolbar /> */}
    </ThemeProvider>
  );
}

export default MainMenu;
